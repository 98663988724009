$red-text: #ff253a;

.landingPage {
  color: white;
  font-family: 'Montserrat', sans-serif;

  &__container {
    height: 100vh;
  }

  &__linksContainer {
    margin-top: 10px;
    display: flex;
  }

  &__contents {
    margin: 0;
    position: absolute;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &__programDetails {
    font-weight: 600;
    font-style: italic;
    padding-bottom: 10px;
  }

  &__name {
    font-size: 64px;
    font-style: italic;
    font-weight: 600;
  }

  &__firstName {
    padding-right: 15px;
  }

  &__programName {
    padding-right: 5px;
  }

  &__programDuration {
    color: $red-text;
  }

  &__lastName {
    color: $red-text;
  }
}