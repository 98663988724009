.thumbnail-title {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 500;
}

.thumbnail-hover {
  filter: brightness(0.25) drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.8));
}

.thumbnail-shadow {
  filter: drop-shadow(7px 7px 9px rgba(0, 0, 0, 0.3));
}