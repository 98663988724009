.sectionContent {
  &__container {
    height: 100vh;
  }

  &__content {
    margin: 0;
    position: relative;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &__row {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__img {
    padding-top: 10px;
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
  }

  &__tagline {
    font-weight: 400;
    font-size: 20px;
  }

  &__description {
    font-style: normal;
    font-weight: 200;
  }

  &__date {
    font-weight: 700;
    font-size: 20px;
    text-align: right;
    padding-top: 15px;
  }
}