.landingPageLinkChip__container {
  background: #ff253a;
  border-radius: 500px;
  padding: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
  margin-right: 10px;
}

.landingPageLinkChip__container:hover {
  filter: brightness(75%);
}