.project-gallery {
  position: relative;
  display: flex;
}

.project-gallery-thumbnails {
  display: flex;

  // Stick to bottom
  //position: absolute;
  //bottom: 0;
}

.project-item {
  //max-height: 500px;
}

.thumbnail {
  border: 2px solid black;
}

.content-zoomOut {
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: top center;
  -o-transform: scale(0.75);
  -o-transform-origin: top center;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: top center;
}