html {
  scroll-behavior: smooth;
}

.sidebar-links {
  &__container {
    height: 100vh;
    background-color: #db1727;
  }

  &__links-list {
    // Vertically center
    top: 60%;
    position: absolute;
    -ms-transform: translateY(-85%) translateX(-50%);
    transform: translateY(-85%) translateX(-50%);
    left: 50%;

    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 700;
    text-align: center;
    margin: auto;
  }

  &__profileImg {
    border: 7px solid white;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    margin-bottom: 10px;
  }

  &__link {
    font-size: 24px;
    color: white;
  }

  &__link:hover {
    text-decoration: underline;
  }
}