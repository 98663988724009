.item-selected {
  filter: brightness(0.4);
}

.item-unselected:hover {
  filter: brightness(0.6);
  cursor: pointer;
}

.popover-container {
  background-color: #1f1f1f;
  font-family: 'Montserrat', sans-serif;
}

.project-details {
  padding: 20px 20px 10px 20px;
}

.header-text {
  font-style: italic;
  font-weight: 700;
}

.link-chip:hover {
  cursor: pointer;
}

p {
  margin-bottom: 10px;
}