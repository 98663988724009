.companyCard {
  font-family: 'Montserrat', sans-serif;
}

.card-content {
  text-align: left;
}

.job {
  &__title {
    font-weight: 700;
    font-size: 18px;
  }

  &__date {
    color: white;
    padding-top: 16px;
    font-size: 17px !important;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 700;
  }

  &__company {
    font-weight: 700;
    font-style: italic;
  }

  &__description {
    font-size: 15px;
  }
}

.icon {
  height: 35px;
  width: 35px;
  display: block;
  vertical-align: baseline;
}